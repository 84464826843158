import React from "react";
import styled from "styled-components";
import Home from "./Home";
import PrintIcon from "../assets/images/printicon.png";
import DataTable from "react-data-table-component";
import AllOccasionsData from "./printingscreens/AllOccasionsData";
import { Link } from "react-router-dom";

const UpcomingOccasion = () => {
    const type = "upcoming";
    return (
        <Cover>
            <Home />
            <UpcomingContainer>
                <PrintButton to="/print?datatype=upcoming">
                    <img
                        src={PrintIcon}
                        alt="image"
                    />{" "}
                    Print
                </PrintButton>
                <TableContainer>
                    {/* <DataTable columns={columns} data={data} /> */}
                    <AllOccasionsData type={type} />
                </TableContainer>
            </UpcomingContainer>
        </Cover>
    );
};

export default UpcomingOccasion;
const Cover = styled.div`
    /* margin-top: 100px; */
    /* margin-left: 20%; */
    /* width: 72.8%; */
    height: 360px;
    padding: 30px;
    color: #000;
    position: relative;
`;
const UpcomingContainer = styled.div`
    width: 95%;
    height: 250px;
    /* background: red;      */
    left: 5%;
    position: absolute;
    top: 55%;
`;
const PrintButton = styled(Link)`
    width: 100px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    border: 1px solid #e1dede;
    border-radius: 5px;
    img {
        margin-right: 5px;
    }
`;
const TableContainer = styled.div`
    width: 100%;
    background: #fff;
    height: calc(100vh - 65vh);
    /* color:#000; */
    /* background: red; */
    overflow-y: scroll;
    margin-top: 45px;
`;
