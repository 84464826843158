import logo from './logo.svg';
import './App.css';
import MainRouter from './components/routers/router/MainRouter';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrintScreenCover from './components/screens/printingscreens/PrintScreenCover';
import Login from './components/screens/login/Login';
import Store from './components/Context/Store';

function App() {
  return (
    <Store>
      {/* <MainRouter/> */}
      <BrowserRouter>
        <MainRouter/>
      </BrowserRouter>
    </Store>
  );
}

export default App;
