import React, { useState } from "react";

//packages
import jsPDF from "jspdf";
import styled from "styled-components";

//images
import PrintIcon from "../assets/images/printicon.png";

//components
import Home from "./Home";
import WomensDayData from "./printingscreens/WomensDayData";

function WomensDay() {
    return (
        <Cover>
            <Home />
            <UpcomingContainer>
                <PrintButton
                    href={`https://api-pre-booking.dett.app/api/v1/bookings/special-occasions/pdf/?event=womens_day`}
                >
                    <img
                        src={PrintIcon}
                        alt="print-icon"
                    />
                    Print
                </PrintButton>
                <TableContainer>
                    <WomensDayData />
                </TableContainer>
            </UpcomingContainer>
        </Cover>
    );
}

export default WomensDay;

const Cover = styled.div`
    height: 360px;
    padding: 30px;
    color: #000;
    position: relative;
`;

const UpcomingContainer = styled.div`
    width: 95%;
    height: 250px;
    left: 5%;
    position: absolute;
    top: 55%;
`;

const PrintButton = styled.a`
    width: 100px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    border: 1px solid #e1dede;
    border-radius: 5px;
    cursor: pointer;
    img {
        margin-right: 5px;
    }
`;

const TableContainer = styled.div`
    width: 100%;
    background: #fff;
    height: calc(100vh - 51vh);
    overflow-y: scroll;
    margin-top: 45px;
`;
