import React, { useContext, useState } from "react";
import styled from "styled-components";
import DettLogo from "../assets/images/dettlogo.png";
import DownArrow from "../assets/images/downarrow.png";
import DashBoardIco from "../assets/images/DashBoardico.png";
import PreBookIco from "../assets/images/prebookico.png";
import OccasionCalender from "../assets/images/occasioncalender.png";
import AgentsIcon from "../assets/images/agentsicon.png";
import PageRoleIco from "../assets/images/pageroleicon.png";
import ClintsIco from "../assets/images/clientsico.png";
import FormFieldIco from "../assets/images/formfieldico.png";
import SelectIco from "../assets/images/selectico.png";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Store";
// import CloseIcon from "../../assets/images/closeicon.png";
import CloseIcon from "../assets/images/closeicon.png";

const SideBar = () => {
    const [optionActive, setOptionActive] = useState();
    const navigate = useNavigate();
    const {
        state: { isSideBar },
    } = useContext(Context);
    const options = [
        {
            id: 1,
            name: "Pre-booking",
            icon: PreBookIco,
            SubOptions: [
                { name: "Upcoming Occasions", ico: SelectIco, url: "/upcomingoccasion" },
                { name: "All occasions", ico: SelectIco, url: "/alloccasion" },
            ],
        },
        {
            id: 2,
            name: "Special-occassions",
            icon: PreBookIco,
            SubOptions: [{ name: "Womens day", ico: SelectIco, url: "/womensday" }],
        },
        {
            id: 3,
            name: "Occasion Calender",
            icon: OccasionCalender,
            SubOptions: [
                { name: "Occasion Calender", ico: SelectIco, url: "/calendar" },
                // { name: "All occasions",ico:SelectIco,url:'alloccasion' },
            ],
        },
        {
            id: 4,
            name: "Agents",
            icon: AgentsIcon,
            SubOptions: [
                { name: "Create agents", ico: SelectIco, url: "/agent" },
                { name: "View agents", ico: SelectIco, url: "/agentdata" },
            ],
        },
        {
            id: 5,
            name: "Page roles",
            icon: PageRoleIco,
            SubOptions: [
                { name: "Add Users", ico: SelectIco, url: "/adduser" },
                { name: "View Users", ico: SelectIco, url: "/viewusers" },
            ],
        },
        {
            id: 6,
            name: "Registered Users",
            icon: ClintsIco,
            SubOptions: [
                { name: "View Users", ico: SelectIco, url: "registerd-user" },
                { name: "---------", ico: SelectIco },
            ],
        },
        {
            id: 7,
            name: "Form fields",
            icon: FormFieldIco,
            SubOptions: [
                { name: "Upcoming Occasions", ico: SelectIco },
                { name: "All occasions", ico: SelectIco },
            ],
        },
    ];
    const { state, dispatch } = useContext(Context);
    const handleChange = (item) => {};
    return (
        <>
            <Cover isSideBar={isSideBar}>
                <CloseButton
                    onClick={() => {
                        dispatch({
                            type: "UPDATE_SIDEBAR",
                        });
                    }}
                >
                    <img
                        src={CloseIcon}
                        alt="image"
                    />
                </CloseButton>
                <LogoContainer>
                    <img
                        src={DettLogo}
                        alt="image"
                    />
                </LogoContainer>
                <OptionSelector>
                    <Head onClick={() => navigate("/")}>
                        <img
                            src={DashBoardIco}
                            alt="image"
                        />
                        Dashboard
                    </Head>
                    <PreBookOptions>
                        {options?.map((item) => (
                            <MainHead>
                                <HeadCover onClick={() => setOptionActive(item.id)}>
                                    <ContentCover>
                                        <img
                                            src={item.icon}
                                            alt={item.name}
                                        />
                                        <h3>{item.name}</h3>
                                    </ContentCover>
                                    <Arrow className={optionActive === item.id && "active"}>
                                        <img
                                            src={DownArrow}
                                            alt="image"
                                        />
                                    </Arrow>
                                </HeadCover>
                                {/* {item.id === optionActive && ( */}
                                <SubOptions className={item.id === optionActive && "active"}>
                                    {item?.SubOptions?.map((subs) => (
                                        <SubContain onClick={() => navigate(`${subs.url}`)}>
                                            <img
                                                src={subs.ico}
                                                alt="image"
                                            />
                                            <h5>{subs?.name}</h5>
                                        </SubContain>
                                    ))}
                                </SubOptions>
                                {/* )} */}
                            </MainHead>
                        ))}
                    </PreBookOptions>
                </OptionSelector>
            </Cover>
        </>
    );
};

export default SideBar;
const Cover = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 18%;
    height: 100vh;
    /* overflow: hidden; */
    background-color: #2c3333;
    text-align: center;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 880px) {
        left: -100%;
    }
    @media (max-width: 640px) {
        left: ${({ isSideBar }) => (isSideBar ? "0" : "-150%")};
        z-index: 20;
        width: 90%;
        transition: 0.8s ease;
    }
`;
const CloseButton = styled.div`
    position: absolute;
    display: none;
    background-color: #fff;
    border-radius: 50%;
    /* width:50px;
    height:50px; */
    @media (max-width: 640px) {
        display: block;
    }
`;
const SubContain = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-start;
    /* margin-left:30px; */
    height: 30px;
    width: 95%;
    img {
        width: 15px;
        object-fit: contain;
        margin-right: 5px;
    }
    h5 {
        color: #bcbcbc;
        /* margin-top: 5px; */
        font-size: 14px;
        margin: 0;
    }
`;
const LogoContainer = styled.div`
    width: 80px;
    height: 80px;
    background: #fff;
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 80%;
    }
    /* margin-top: 100px; */
`;
const ContentCover = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-right: 5px;
    }
`;
const OptionSelector = styled.div`
    /* height: 30px; */
    margin-top: 20px;
    max-height: 450px;
    color: #bcbcbc;
    overflow: scroll;
    text-align: left;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Head = styled.div`
    color: #bcbcbc;
    font-size: 29px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        margin-right: 5px;
    }
    @media (max-width: 980px) {
        font-size: 22px;
    }
`;
const PreBookOptions = styled.div`
    /* display: flex; */
    /* justify-content: space-between; */
    cursor: pointer;
    margin-top: 10px;
    position: relative;
    height: 0%;
    overflow: hidden;
`;
const MainHead = styled.div`
    transition: all 0.4s ease;
`;
const Arrow = styled.div`
    transform: rotate(-90deg);
    transition: all 0.4s ease;

    &.active {
        transform: rotate(0deg);
        transition: all 0.4s ease;
    }
`;
const SubOptions = styled.div`
    text-align: right;
    height: 0;
    overflow: hidden;
    transition: 0.4s ease;
    &.active {
        height: 60px;
        transition: 0.4s ease;
    }

    /* padding: 5px; */
`;
const HeadCover = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #bcbcbc;
    h3 {
        font-size: 15px;
        color: #bcbcbc;
    }
`;
