import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import GradientImage from "../assets/images/gradientimageone.png";
import DesignGradient from "../assets/images/designgradient.png";
import { accountsConfig } from "../helpers/axiosConfig";
import { Context } from "../Context/Store";
import { useNavigate } from "react-router-dom";

const Home = ({ type }) => {
    const { state, dispatch } = useContext(Context);
    const { access } = state.userData;
    const navigate = useNavigate();
    useEffect(() => {
        accountsConfig
            .get("api/v1/bookings/upcoming/bookings/list/", {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
            })
            .then(function (response) {
                dispatch({
                    type: "UPDATE_GENERAL_DATA",
                    payload: {
                        upcomingOccasion: response.data.booking_count,
                    },
                });
            });
        accountsConfig
            .get("api/v1/bookings/clients/list", {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
            })
            .then(function (response) {
                dispatch({
                    type: "UPDATE_GENERAL_DATA",
                    payload: {
                        client: response.data.data.profile_count,
                    },
                });
            });
        accountsConfig
            .get("api/v1/bookings/agents/list", {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
            })
            .then(function (response) {
                dispatch({
                    type: "UPDATE_GENERAL_DATA",
                    payload: {
                        agentsCount: response.data.data.agent_count,
                    },
                });
            });
        accountsConfig
            .get("api/v1/bookings/bookings/list", {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
                params: {
                    page: 1,
                    instances_count: 20,
                },
            })
            .then(function (response) {
                console.log(response, "response");
                dispatch({
                    type: "UPDATE_GENERAL_DATA",
                    payload: {
                        alloccasion: response.data.all_bookings_count,
                    },
                });
            })
            .catch((error) => {
                console.log(error, "error");
                console.log(error.message, "error message");
            });
    }, []);
    return (
        <Cover>
            <Header>
                <Title>Dett Pre-booking{type === "alloccassion" ? "All Occassion" : ""}</Title>
                <More>Visit site</More>
            </Header>
            <BottomContainer>
                <CardWidget onClick={() => navigate("/upcomingoccasion")}>
                    <BackImageOne>{/* <img src={GradientImage} alt='image'/> */}</BackImageOne>
                    <Gradient>
                        <img
                            src={DesignGradient}
                            alt="image"
                        />
                    </Gradient>
                    <ContentsInside>
                        <h4>{state.generalData.upcomingOccasion}</h4>
                        <h5>Upcoming occasions</h5>
                    </ContentsInside>
                </CardWidget>
                <CardWidget onClick={() => navigate("/alloccasion")}>
                    <BackImageTwo>{/* <img src={GradientImage} alt='image'/> */}</BackImageTwo>
                    <Gradient>
                        <img
                            src={DesignGradient}
                            alt="image"
                        />
                    </Gradient>
                    <ContentsInside>
                        <h4>{state.generalData.alloccasion}</h4>
                        <h5>All occasions</h5>
                    </ContentsInside>
                </CardWidget>
                <CardWidget onClick={() => navigate("/registerd-user")}>
                    <BackImageThree>{/* <img src={GradientImage} alt='image'/> */}</BackImageThree>
                    <Gradient>
                        <img
                            src={DesignGradient}
                            alt="image"
                        />
                    </Gradient>
                    <ContentsInside>
                        <h4>{state.generalData.client}</h4>
                        <h5>Clients</h5>
                    </ContentsInside>
                </CardWidget>
                <CardWidget onClick={() => navigate("/agentdata")}>
                    <BackImageFour>{/* <img src={GradientImage} alt='image'/> */}</BackImageFour>
                    <Gradient>
                        <img
                            src={DesignGradient}
                            alt="image"
                        />
                    </Gradient>
                    <ContentsInside>
                        <h4>{state.generalData.agentsCount}</h4>
                        <h5>Agents</h5>
                    </ContentsInside>
                </CardWidget>
            </BottomContainer>
        </Cover>
    );
};

export default Home;
const Cover = styled.div`
    background: #ebebeb;
    /* margin-top:130px;
 margin-left:22%; */
    width: 100%;
    height: 360px;
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 480px) {
        padding-top: 30px;
    }
`;
const Header = styled.div``;
const Title = styled.div`
    color: #2c3333;
    font-weight: 600;
    font-size: 24px;
`;
const More = styled.div`
    color: #bcbcbc;
    font-size: 14px;
`;
const CardContent = styled.div``;
const CardWidget = styled.div`
    width: 24%;
    height: 120px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #707070;
    @media (max-width: 768px) {
        width: 48%;
        margin-bottom: 10px;
    }
    @media (max-width: 480px) {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
`;
const BackImageOne = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    background-color: #f05454;
    img {
        width: 100%;
    }
`;
const ContentsInside = styled.div`
    position: absolute;
    left: 10%;
    top: 30%;
    cursor: pointer;
    h4 {
        margin: 0;
        font-size: 24px;
        color: #fff;
    }
    h5 {
        margin: 0;
        color: #fff;
        font-size: 18px;
    }
`;
const BackImageTwo = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    background-color: #12947f;
    img {
        width: 100%;
    }
`;
const BackImageThree = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    background-color: #001e6c;
    img {
        width: 100%;
    }
`;
const BackImageFour = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    background-color: #f17808;
    img {
        width: 100%;
    }
`;
const BottomContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const Gradient = styled.div`
    position: absolute;
    width: 80%;
    bottom: 0;
    top: 30%;
    img {
        width: 100%;
    }
    @media (max-width: 880px) {
        display: none;
    }
`;
