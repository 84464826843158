import React, { useContext, useEffect,useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../Context/Store";
import { accountsConfig } from "../../helpers/axiosConfig";

const AllAgentData = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type");
    const [dropDown,setDropDown]=useState(false)
    const [data,setData]=useState([])
    const {state:{userData:{access}},}=useContext(Context)
    const columns = [
        {
            name: "Si.No",
        },
        {
            name: "Name",
        },
        {
            name: "Number",
        },
        {
            name: "Address",
        },
        {
            name: "Joining date",
        },
        {
            name: "AgentCode",
        },
    ];
    useEffect(()=>{
        accountsConfig.get('api/v1/accounts/agent/list/',{
            headers: {
                Authorization: `Bearer ${access}`,
            },
        }).then(function(response){
            setData(response.data.data.data);
        })
    },[])

    return (
        <Cover type={type}>
            <Table>
                <tbody>
                    <TableCover>
                        <TableCover>
                            {columns.map((item) => (
                                <TableHead type={item}>{item.name}</TableHead>
                            ))}
                        </TableCover>
                        {data.map((item,index) => (
                            <TableCover>
                                <TableData type='sno'>{index+1}</TableData>
                                <TableData>{item.name}</TableData>
                                <TableData>{item.phone}</TableData>
                                <TableData>{item.address}</TableData>
                                <TableData>{item.joined_date}</TableData>
                                <TableData>{item.agent_code}</TableData>
                                {/* <TableDataStatus onClick={()=>setDropDown(item.id)} status={item.status}>
                                    {item.status}
                                    <Dropdown className={item.id === dropDown && 'active'}>
                                        <DataContain>Confirm</DataContain>
                                        <DataContain>Rejected</DataContain>
                                        <DataContain>Pending</DataContain>
                                        <DataContain>Not answered</DataContain>
                                    </Dropdown>
                                </TableDataStatus> */}
                            </TableCover>
                        ))}
                    </TableCover>
                </tbody>
            </Table>
        </Cover>
    );
};

export default AllAgentData;
const Cover = styled.div`
    background: #fff;
    /* background-color: ${({ type }) =>
        type === "all-occassion" ? "red" : "#fff"}; */
    /* padding: 20px; */
    width: 98%;
    margin: 0 auto;
`;
const Table = styled.table`
    /* border: 1px solid #000; */
    width: 100%;
    tbody{
        width: 100%;
    }
`;
const Dropdown = styled.div`
    position: absolute;
    width: 120px;
    background-color: #fff;
    display: none;
    z-index: 20;
    left: -10%;
    border:1px solid #707070;
    border-radius:8px;
    overflow: hidden;

    &.active{
        display: block;
    }

`
const DataContain = styled.div`
    background-color: #fff;
    border-bottom:1px solid #707070;
    text-align: center;
    color:#707070;
    &:last-child{
        border-bottom: none;
    }
`
const TableCover = styled.tr`
    /* border: 1px solid #000; */
`;
const TableHead = styled.th`
    /* width: 12%; */
    width:${({type})=>type.name === 'Si.No' ? '5%' : type.status === 'Status' ? '15%' : '11%'};
    border-right: 1px solid #B9B9B9;
    border-bottom: 1px solid #B9B9B9;
    border-top: 1px solid #B9B9B9;
    &:first-child{
        border-left: 1px solid #B9B9B9;
        
    }
    
`;
const TableData = styled.td`
    border-right: 1px solid #B9B9B9;
    border-bottom: 1px solid #B9B9B9;
    text-align: center;
    &:first-child{
        border-left: 1px solid #B9B9B9;
    }
    
`;
const TableDataStatus = styled.td`
    /* border: 1px solid #000; */
    cursor: pointer;
    position: relative;
    color: ${({ status }) =>
        status === "confirm"
            ? "#31A818"
            : status === "rejected"
            ? "#FF0000"
            : status === "Pending"
            ? "#FF9101"
            : "#161261"};
    border-right: 1px solid #B9B9B9;
    border-bottom: 1px solid #B9B9B9;
`;
