import React from "react";
import Home from "./Home";
import styled from "styled-components";

const HomeCover = () => {
    return (
        <Cover>
            <Home />
        </Cover>
    );
};

export default HomeCover;
const Cover = styled.div`
    /* margin-top: 100px; */
    /* margin-left: 20%; */
    /* width: 72.8%; */
    height: 360px;
    padding: 30px;
    @media (max-width:880px){
        width:100%;
    }
    @media(max-width:640px){
        padding-left:0;
        padding-top:50px;
  }
`;
