import React,{useEffect} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import AllOccasionsData from './AllOccasionsData'

const PrintScreenCover = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const datatype = searchParams.get("datatype");
    useEffect(()=>{
      setTimeout(() => {
            window.print();
            
        }, 2000);
        // navigate('/alloccasion')
    },[])
  return (
    <Cover datatype={datatype}>
        <AllOccasionsData/>
    </Cover>
  )
}

export default PrintScreenCover
const Cover = styled.div``