import React, { useContext } from 'react'
import styled from 'styled-components'
import SearchIcon from '../assets/images/serachico.png'
import MessageIcon from '../assets/images/messageicon.png'
import NotificationIcon from '../assets/images/notificationicon.png'
import AvatarIcon from '../assets/images/avataricon.png'
import { useNavigate } from 'react-router-dom'
import { Context } from '../Context/Store'
// import {Outlets}  from 'react-router-dom'
const Topbar = () => {
  const {state,dispatch}=useContext(Context)
  const navigate = useNavigate()
  const handleLogOut=()=>{
    localStorage.clear()
    dispatch({
      type:"UPDATE_USER_DATA",
      payload:{
        isVerified:false,
        access:''
      }
    })
    navigate('/')
  }
  const handleSideBar=()=>{
    dispatch({
      type:"UPDATE_SIDEBAR"
    })
  }
  return (
    <Cover>
      <Left>
        <InputContainer>
          <InputBox type="text" placeholder="Search for ...."></InputBox>
          <SearchImage>
            <img src={SearchIcon} alt='image'/>
          </SearchImage>
        </InputContainer>
        <MenuIcon onClick={()=>handleSideBar()}>
          <SpanOne></SpanOne>
          <SpanTwo></SpanTwo>
          <SpanThree></SpanThree>
        </MenuIcon>
      </Left>
      <Right>
        <MessageContainer>
          <ImageMessage>
            <img src={MessageIcon} alt='image'/>
          </ImageMessage>
        </MessageContainer>
        <MessageContainer>
          <ImageMessage>
            <img src={NotificationIcon} alt='image'/>
          </ImageMessage>
        </MessageContainer>
        <SignOutButton onClick={()=>handleLogOut()}>
          Sign Out
        </SignOutButton>
        <AvatarImage>
          <img src={AvatarIcon} alt='image'/>
        </AvatarImage>
      </Right>
    </Cover>
  )
}

export default Topbar
const Cover = styled.div`
  /* margin-left:50%; */
  position: fixed;
  color:#000;
  left:22%;
  top:2%;
  width: 76%;
  height:80px;
  background-color:#2C3333;
  display: flex;
  /* padding-right:50px ; */
  justify-content:space-between;
  align-items: center;
  padding-left:20px ;
  @media(max-width:880px){
    left:0;
    width: 100%;
    z-index:  10;
  }
  @media (max-width:640px){
    width: 95%;
  }
  

`
const SpanOne = styled.div`
  width: 100%;
  height:20%;
  background-color: #fff;
  border-radius:10px;
`
const SpanTwo = styled.div`
  width: 80%;
  height:20%;
  background-color: #fff;
  border-radius:10px;
`
const SpanThree = styled.div`
  width: 60%;
  height:20%;
  background-color: #fff;
  border-radius:10px;
`
const MenuIcon = styled.div`
  /* display: none; */
  display: block;
  @media(max-width:640px){
    display: block;
    /* background: red; */
    width: 40px;
    height:30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
const Left = styled.div``
const InputContainer = styled.div`
  display:flex;
  width: 300px;
  height:30px;
  align-items: center;
  background-color: #EBEBEB;
  @media(max-width:640px){
    display: none;
  }
`
const InputBox = styled.input`
  background:transparent;
  border:none;
  outline:none;
  width: 90%;
`
const SearchImage = styled.div`
  width: 7%;
  img{
    width:100%;
  }
`
const Right = styled.div`
  display:flex;
  align-items: center;
  margin-right: 28px;
  position: relative;
`
const MessageContainer = styled.div`
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  @media(max-width:640px){
    display: none;
  }
`
const ImageMessage = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;

`
const SignOutButton = styled.div`
  color:#BCBCBC;
  cursor:pointer;
`
const AvatarImage = styled.div`
  /* position: absolute; */
  /* right:-50%;
  top:-10%; */
  margin-left: 20px;
  width:60px;
  img{
    width:100%;
  }
`