import React from "react";
import Lottie from "react-lottie";
import Loader from "./87172-loader.json";

export default function PageLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Loader,
        rendererSettings: {},
    };
    return <Lottie options={defaultOptions} height={105} width={105} />;
}
