import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../Context/Store";
import { accountsConfig } from "../helpers/axiosConfig";
import PageLoader from "../helpers/loaders/PageLoader";
import AgentModal from "./modals/AgentModal";
import UserAddedModal from "./modals/UserAddedModal";

const AgentForm = () => {
    const [activeAgent, setActiveAgent] = useState(false);
    const [drop, setDrop] = useState(false);
    const [selection, setSelection] = useState("");
    const [is_toggle,setToggle]= useState(false);
    const [id, setId] = useState();
    const [userName,setUserName] = useState('')
    const [userRole,setRole] = useState('')
    const [privilages,setPrivilages]= useState([])
    const [someArray,setSomeArray]= useState([])
    const {state:{userData:{access}}}= useContext(Context)
    const dataRole = [
        {
            id: 1,
            name: "Admin",
        },
        {
            id: 2,
            name: "Editor",
        },
        {
            id: 3,
            name: "Manager",
        },
        {
            id: 4,
            name: "Modarator",
        },
    ];
    const [dataPrivilage,setDataPrivilage] =useState( [
        {
            id: 1,
            name: "Form Field",
            is_active: false
        },
        {
            id: 2,
            name: "Occasion calender",
            is_active: false
        },
        {
            id: 3,
            name: "Agents",
            is_active: false
        },
        {
            id: 4,
            name: "Pre-booking",
            is_active: false
        },
        {
            id: 5,
            name: "Registered users",
            is_active: false
        },
    ]);


    const handlePrivilage = (item,index)=>{
        item.is_active = !item.is_active;
        setId(item.id);
        setToggle(!is_toggle);
    }

    useEffect(()=>{
        const filteredArray = dataPrivilage.filter((item)=> item.is_active)
        setSomeArray(filteredArray)
    }, [is_toggle])
    
    const handleSubmit = () => {
      accountsConfig.post('api/v1/accounts/create-chief/',{
          name: userName,
          role: selection
      },{
        headers: {
            Authorization: `Bearer ${access}`,
        },
    }).then(function(response) {
        if(response.data.StatusCode === 6000){
            setActiveAgent(true);
            setUserName('');
            setSelection('');
        }
    })
    }


    return (
        <>
            <Cover>
                <Top>
                    <h4>Dett Pre-booking (Add User)</h4>
                    <h5>Visit site</h5>
                </Top>
               
                <FormSection>
                    <SectionCover>
                        <Name>
                            <Label>Enter user name</Label>
                            <InputCover>
                                <InputField type="text" onChange={(e)=>setUserName(e.target.value)} value={userName}/>
                            </InputCover>
                        </Name>
                        <Name>
                            <Label>Enter user role</Label>
                            <InputCover
                                onClick={() => setDrop((prev) => !prev)}
                            >
                                <InputFieldDrop type="text" value={selection}/>
                                <Dropdown className={drop && "active"}>
                                    {dataRole.map((item) => (
                                        <DataContain
                                            
                                            onClick={() =>
                                                setSelection(item.name)
                                            }
                                        >
                                            {item.name}
                                        </DataContain>
                                    ))}
                                </Dropdown>
                            </InputCover>
                        </Name>
                        <Name>
                            <Label>Asign privileges</Label>
                            <InputCoverSelect>
                                {dataPrivilage.map((item,index) => (
                                    <BoxSelect onClick={()=>handlePrivilage(item,index)}  className={item.is_active ? 'active' :''}>{item.name}</BoxSelect>
                                ))}
                            </InputCoverSelect>
                        </Name>
                    </SectionCover>
                    <ButtonSubmit
                        onClick={() => handleSubmit()}
                    >
                        Add Now
                    </ButtonSubmit>
                </FormSection>
                {/* <AgentModal
                    activeAgent={activeAgent}
                    setActiveAgent={setActiveAgent}
                /> */}
                <UserAddedModal
                    activeAgent={activeAgent}
                    setActiveAgent={setActiveAgent}
                />
            </Cover>
        </>
    );
};

export default AgentForm;
const Cover = styled.div`
    /* margin-top: 120px;
    margin-left: 22%; */
    margin-left: 28px;
    margin-top: 117px;
    /* width: 72.8%; */
    background: #ebebeb;
    /* margin-top:30px; */
    width: 94%;
    /* height: 390px; */
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 30px;
    color: #000;
    position: relative;
`;
const Top = styled.div`
    h4 {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        color: #2c3333;
    }
    h5 {
        margin: 0;
        color: #bcbcbc;
        cursor: pointer;
        margin-top: 10px;
    }
`;
const Dropdown = styled.div`
    height: 0;
    transition: 0.4s ease;
    &.active {
        height: 90px;
    }
`;
const DataContain = styled.div`
    border-bottom: 1px solid #000;
    &:first-child {
        border-top: 1px solid #000;
    }
    &:last-child {
        border-bottom: none;
    }
    &.active{
        background: red;
    }
`;
const FormSection = styled.div`
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    position: relative;
`;
const BoxSelect = styled.span`
    padding: 8px;
    border: 1px solid #707070;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 8px;
    &.active{
        /* background-color: red; */
        border:1px solid #ED4814;
    }
`;
const Name = styled.div`
    display: flex;
    /* justify-content: space-between; */
    margin-top: 20px;
`;
const Label = styled.div`
    width: 30%;
`;
const SectionCover = styled.div`
    width: 70%;
`;
const InputCover = styled.div`
    border: 1px solid #707070;
    width: 50%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 30px;
`;
const InputCoverSelect = styled.div`
    /* border: 1px solid #707070; */
    width: 60%;
    /* overflow: hidden; */
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    border-radius: 4px;
`;
const InputField = styled.input`
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
`;
const InputFieldDrop = styled.input`
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
`;
const ButtonSubmit = styled.div`
    width: 150px;
    height: 50px;
    background: green;
    float: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
`;
