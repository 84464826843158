const reducers = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const userData = { ...state.userData, ...action.payload };
            localStorage.setItem("userData", JSON.stringify(userData));
            return {
                ...state,
                userData: userData,
            };
        case "UPDATE_GENERAL_DATA":
            const generalData = { ...state.generalData, ...action.payload };
            return {
                ...state,
                generalData: generalData,
            };
        case "UPDATE_SIDEBAR":
            return {
                ...state,
                isSideBar:!state.isSideBar
            }
        case "UPDATE_SELECTED_MONTH":
            return {
                ...state,
                selectedMonth:action.selectedMonth
            }
            
        default:
            return state;
    }
};

export default reducers;
