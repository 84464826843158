import React, { useContext, useEffect, useState } from "react";

//packages
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

//contest
import { Context } from "../../Context/Store";

//axios
import { accountsConfig } from "../../helpers/axiosConfig";

const WomensDayData = () => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get("datatype");

    //contest
    const { state } = useContext(Context);
    const { access } = state.userData;

    //states
    const [allData, setAllData] = useState([]);

    const columns = [
        {
            name: "Si.No",
        },
        {
            name: "Sender",
        },
        {
            name: "phone",
        },
        {
            name: "Whatsapp No",
        },
        {
            name: "Dob",
        },
        {
            name: "Address",
        },
        {
            name: "City",
        },
        {
            name: "District",
        },
        {
            name: "Pincode",
        },
        {
            name: "Housename",
        },
        {
            name: "Streetname",
        },
        {
            name: "Recipient",
        },
        {
            name: "Phone",
        },
        {
            name: "Whatsapp No",
        },
        {
            name: "Dob",
        },
        {
            name: "Address",
        },
        {
            name: "City",
        },
        {
            name: "District",
        },
        {
            name: "Pincode",
        },
        {
            name: "Housename",
        },
        {
            name: "Streetname",
        },
    ];

    //special occassion api
    useEffect(() => {
        accountsConfig
            .get("api/v1/bookings/special-occasions/", {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
                params: {
                    occasion: "womens_day",
                },
            })
            .then(function (res) {
                setAllData(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <Cover type={type}>
            <Table id="womens-day-data">
                <tbody>
                    <TableCover>
                        <TableCover>
                            {columns.map((item) => (
                                <TableHead type={item}>{item.name}</TableHead>
                            ))}
                        </TableCover>
                        {allData.map((item, index) => {
                            return (
                                <TableCover>
                                    <TableData>{index + 1}</TableData>
                                    <TableData>{item.sender.name}</TableData>
                                    <TableData>{item.sender.phone}</TableData>
                                    <TableData>{item.sender.whatsapp_number}</TableData>
                                    <TableData>
                                        {moment(item.sender.dob).format("DD-MM-YYYY")}
                                    </TableData>
                                    <TableData>{item.sender.address}</TableData>
                                    <TableData>{item.sender.city}</TableData>
                                    <TableData>{item.sender.district}</TableData>
                                    <TableData>{item.sender.pin_code}</TableData>
                                    <TableData>{item.sender.house_name}</TableData>
                                    <TableData>{item.sender.street_name}</TableData>

                                    <TableData>{item.recipient.name}</TableData>
                                    <TableData>{item.recipient.phone}</TableData>
                                    <TableData>{item.recipient.whatsapp_number}</TableData>
                                    <TableData>
                                        {item.recipient.dob
                                            ? moment(item.recipient.dob).format("DD-MM-YYYY")
                                            : null}
                                    </TableData>
                                    <TableData>{item.recipient.address}</TableData>
                                    <TableData>{item.recipient.city}</TableData>
                                    <TableData>{item.recipient.district}</TableData>
                                    <TableData>{item.recipient.pin_code}</TableData>
                                    <TableData>{item.recipient.house_name}</TableData>
                                    <TableData>{item.recipient.street_name}</TableData>
                                </TableCover>
                            );
                        })}
                    </TableCover>
                </tbody>
            </Table>
        </Cover>
    );
};

export default WomensDayData;
const Cover = styled.div`
    background: #fff;
    width: auto;
    margin: 0 auto;
`;

const Table = styled.table`
    min-width: max-content;
    tbody {
        tr {
            th {
                width: 200px;
                &:first-child {
                    width: 50px;
                }
                &:nth-child(3),
                :nth-child(4) {
                    width: 140px;
                }
                &:nth-child(5) {
                    width: 100px;
                }
                &:nth-child(6) {
                    width: 270px;
                }
                &:nth-child(9) {
                    width: 80px;
                }
                &:nth-child(10) {
                    width: 250px;
                }
                &:nth-child(12),
                :nth-child(13),
                :nth-child(14) {
                    width: 140px;
                }
                &:nth-child(15) {
                    width: 100px;
                }
                &:nth-child(16) {
                    width: 250px;
                }
                &:nth-child(19) {
                    width: 100px;
                }
            }
        }
    }
`;

const TableCover = styled.tr``;

const TableHead = styled.th`
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
`;

const TableData = styled.td`
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    text-align: center;
    height: 50px;
`;
