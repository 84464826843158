import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AllOccasionsData from './AllOccasionsData'
import AllUserData from './AllUserData'

const UserPrintScreen = () => {
    const navigate = useNavigate()
    useEffect(()=>{
      setTimeout(() => {
            window.print();
            navigate('/viewusers')
        }, 500);
    },[])
  return (
    <Cover>
        <AllUserData/>
    </Cover>
  )
}

export default UserPrintScreen
const Cover = styled.div``