import React,{useContext, useState} from "react";
import styled from "styled-components";
import DettLogo from "../../assets/images/dettlogo.png";
import { Context } from "../../Context/Store";
import { accountsConfig } from "../../helpers/axiosConfig";

const Login = () => {
    const {state,dispatch}= useContext(Context)
    const [username,setUsername]=useState('');
    const [password,setPassword]=useState('');
    const [focused,setFocused]=useState('');
    const [error,setError]=useState(false);
    const [errorMessage,setErrorMessage]=useState('')
    const Access_key = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5NjA1OTUwLCJpYXQiOjE2NDkxNzM5NTAsImp0aSI6ImM1ZDRlY2NmZDZlYzQ5Yzg5Nzc4NTc5Yzg1Y2E2MWUxIiwidXNlcl9pZCI6MX0.JCRGCmrD_7CFH1Xqw2JXjoYKcF7AOJh2Odzp27LxiTw'
    // const Access_key = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ3MTY0NzMxLCJpYXQiOjE2NDY3MzI3MzEsImp0aSI6IjQ5YmIyYzRhNDc4YjRhMDU5OWZiNDg1MmZmNTI5ODlkIiwidXNlcl9pZCI6MX0.fnRYpzCKZLbyTwkwCnMlKKdnTNsblftZDTWwabvWiPU'
    const handleLogin=()=>{
        accountsConfig.post('api/v1/accounts/user/login/',{
            username: username,
            password: password
        },{
        // headers: {
        //     Authorization: `Bearer ${Access_key}`,
        // }
    }).then(function(response){
            if(response.data.StatusCode===6000){
                dispatch({
                    type: "UPDATE_USER_DATA",
                    payload: {
                        isVerified: true,
                        access:response.data.data.response.access,
                    },
                });
            }else if(response.data.StatusCode===6001){
                setError(true);
                setErrorMessage(response.data.data.message)
            }else{
                setError(true);
                setErrorMessage('server error')
            }
        })
    }
    const handleKeypres=(e)=>{
        if(e.keyCode === 13){
            handleLogin()
        }
    }
    return (
        <Cover>
            <Container>
                <LogoContainer>
                    <img src={DettLogo} alt="dett" />
                </LogoContainer>
                <InputSection>
                    <Label>Enter Your Name</Label>
                    <InputContainer className = {focused === 'name' && 'active' }>
                        <InputField onFocus={() => setFocused('name')} type="text" onChange={(e)=>setUsername(e.target.value)}></InputField>
                    </InputContainer>
                </InputSection>
                <InputSection>
                    <Label>Enter Your Password</Label>
                    <InputContainer className = {focused === 'password' && 'active' }>
                        <InputField type="password" onFocus={() => setFocused('password')} onChange={(e)=>setPassword(e.target.value)} onKeyUp={(e)=>handleKeypres(e)}></InputField>
                    </InputContainer>
                </InputSection>
                <Errormsg>{error && errorMessage}</Errormsg>
                <ButtomLogin onClick={()=>handleLogin()}>Log In</ButtomLogin>
            </Container>
        </Cover>
    );
};

export default Login;
const Cover = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: hidden;
`;
const Container = styled.div`
    width: 400px;
    height: 450px;
    background-color: #ebebeb;
    border-radius: 20px;
    padding: 20px;
    @media(max-width:640px){
        height:100%;
        /* display: flex; */
        padding-top:90%;

    }
`;
const LogoContainer = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom:20px ;
    img {
        width: 80%;
    }
`;
const InputSection = styled.div`
    margin :0 auto;
    /* margin-top:35px; */
    width:80%;
    margin-top:20px;

`
const Label = styled.div`
    color:#141414;
`
const InputContainer = styled.div`
    width:100%;
    /* height:35px; */
    background-color: #fff;
    margin-top:10px;
    /* margin: 0 auto; */
    border-radius:5px;
    overflow: hidden;
    border:1px solid #fff;

    &.active{
        border:1px solid red;
    }
    
`
const InputField = styled.input`
    width: 100%;
    height: 100%;
    border:none;
    outline: none;
    padding:10px;
`
const ButtomLogin = styled.div`
    width: 80%;
    height: 40px;
    cursor: pointer;
    background-color: #EE5B29;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    margin-top: 80px;
    border-radius: 8px;
    font-weight: bold;
`
const Errormsg = styled.h4`
    color:red;
    text-align: center;
    font-weight:400 ;
`