import React,{useEffect, useState} from "react";
import styled from "styled-components";
import CloseIcon from "../../assets/images/closeicon.png";

const AgentModal = ({ activeAgent, setActiveAgent,responseData ,agentName}) => {
    const [text,setText]= useState('DA0101');
    const [copied,setCopied] = useState(false)
    const handleCopy = async () => {
        await navigator.clipboard.writeText(responseData);
        setCopied(true);
        setTimeout(() => {
            setActiveAgent(false)
            setCopied(false);

        }, 1000);
      };
      
    return (
        <Cover activeAgent={activeAgent}>
            <Container>
                <Close onClick={() => setActiveAgent((prev) => !prev)}>
                    <img src={CloseIcon} alt="image" />
                </Close>
                <Content>
                    <Top>Agent code for {agentName} is</Top>
                    <CodeBox>
                        <LeftBox
                        >
                            {responseData}
                        </LeftBox>
                        <RightBox onClick={() => handleCopy()} copied={copied}>{copied ? 'copied' : 'Copy'}</RightBox>
                    </CodeBox>
                </Content>
            </Container>
        </Cover>
    );
};

export default AgentModal;
const Cover = styled.div`
    position: fixed;
    background: #00000080;
    width: 100%;
    height: 100%;
    transform: ${({ activeAgent }) =>
        activeAgent ? "scale(1,1)" : "scale(0,0)"};
    transition: 0.4s ease;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Container = styled.div`
    width: 500px;
    height: 300px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #707070;
    position: relative;
`;
const Content = styled.div``;
const Top = styled.h5`
    font-size: 24px;
    margin: 0;
    font-weight: 400;
`;
const CodeBox = styled.div`
    display: flex;
    width: 180px;
    height: 40px;
    margin: 0 auto;
    background: #ebebeb;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
`;
const LeftBox = styled.div`
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const RightBox = styled.div`
    width: 40%;
    /* background-color: green; */
    background-color: ${({copied})=> copied ? '#4B89D1' : '#12A138'};
    transition:.4s ease ;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
`;
const Close = styled.div`
    position: absolute;
    top: 5%;
    right: 3%;
    width: 20px;
    cursor: pointer;
    height: 20px;
    img {
        width: 100%;
    }
`;
