import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../Context/Store";
import { accountsConfig } from "../../helpers/axiosConfig";
import PageLoader from "../../helpers/loaders/PageLoader";

const UserData = ({ type, selectedMonth, selectedFilter }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const datatype = searchParams.get("datatype");
    const [dropDown, setDropDown] = useState(false);
    const [alldate, setAllDate] = useState([]);
    const [loader,setLoader]= useState(false)
    const { state } = useContext(Context);
    const { access } = state.userData;
    const columns = [
        {
            name: "Si.No",
            selector: (row) => row.id,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Date",
            selector: (row) => row.occasion,
        },
        {
            name: "Contact",
            selector: (row) => row.occasiondate,
        },
        {
            name: "Ajent",
            selector: (row) => row.occasiondate,
        },
    ];

    useEffect(() => {
        setLoader(true)
            accountsConfig
                .get("api/v1/accounts/client/list/", {
                    headers: {
                        Authorization: `Bearer ${access}`,
                    },
                })
                .then(function (res) {
                    console.log(res.data.data.data,'hai hello from me');
                    setLoader(false)
                    setAllDate(res.data.data.data);
                });
    }, []);
    console.log(alldate,'-----all--------date');
    return (
        <Cover type={type}>
            {loader ? <PageLoader/> :<Table>
                <tbody>
                    <TableCover>
                        <TableCover>
                            {columns.map((item) => (
                                <TableHead type={item}>{item.name}</TableHead>
                            ))}
                        </TableCover>
                        {alldate.map((item, index) => (
                            <TableCover>
                                <TableData type="sno">{index + 1}</TableData>
                                <TableData>{item.name}</TableData>
                                {/* <TableData>{item.contact}</TableData> */}
                                {/* <TableData>{item.relation_name}</TableData> */}
                                {/* <TableData>{item.relationship}</TableData> */}
                                <TableData>{item.dob}</TableData>
                                <TableData>{item.phone}</TableData>
                                <TableData>{item.agent_code}</TableData>
                                {/* <TableData>{item.gift_suggestion}</TableData> */}
                                {/* <TableDataStatus
                                    onClick={() => setDropDown(item.id)}
                                    status={item.status}
                                >
                                    {item.status}
                                    <Dropdown
                                        className={
                                            item.id === dropDown && "active"
                                        }
                                    >
                                        <DataContain>Confirm</DataContain>
                                        <DataContain>Rejected</DataContain>
                                        <DataContain>Pending</DataContain>
                                        <DataContain>Not answered</DataContain>
                                    </Dropdown>
                                </TableDataStatus> */}
                            </TableCover>
                        ))}
                    </TableCover>
                </tbody>
            </Table>}
        </Cover>
        // <div>hello world</div>
    );
};

export default UserData;
const Cover = styled.div`
    background: #fff;
    /* background-color: ${({ type }) =>
        type === "all-occassion" ? "red" : "#fff"}; */
    /* padding: 20px; */
    width: 98%;
    margin: 0 auto;
`;
const Table = styled.table`
    /* border: 1px solid #000; */
    width: 100%;
    tbody {
        width: 100%;
    }
`;
const Dropdown = styled.div`
    position: absolute;
    width: 120px;
    background-color: #fff;
    display: none;
    z-index: 20;
    left: -10%;
    border: 1px solid #707070;
    border-radius: 8px;
    overflow: hidden;

    &.active {
        display: block;
    }
`;
const DataContain = styled.div`
    background-color: #fff;
    border-bottom: 1px solid #707070;
    text-align: center;
    color: #707070;
    &:last-child {
        border-bottom: none;
    }
`;
const TableCover = styled.tr`
    /* border: 1px solid #000; */
`;
const TableHead = styled.th`
    /* width: 12%; */
    width: ${({ type }) =>
        type.name === "Si.No"
            ? "5%"
            : type.status === "Status"
            ? "15%"
            : "11%"};
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
`;
const TableData = styled.td`
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    text-align: center;
`;
const TableDataStatus = styled.td`
    /* border: 1px solid #000; */
    cursor: pointer;
    position: relative;
    color: ${({ status }) =>
        status === "confirm"
            ? "#31A818"
            : status === "rejected"
            ? "#FF0000"
            : status === "Pending"
            ? "#FF9101"
            : "#161261"};
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
`;
