import React,{useContext, useState} from "react";
import styled from "styled-components";
import { Context } from "../Context/Store";
import { accountsConfig } from "../helpers/axiosConfig";
import AgentModal from "./modals/AgentModal";

const AgentForm = () => {
    const [activeAgent, setActiveAgent] = useState(false);
    const [agentName, setAgentName] = useState('');
    const [agentAddress, setAgentAddress] = useState('');
    const [agentNumber, setAgentNumber] = useState('');
    const [responseData, setResponseData] = useState('');

    const {state:{userData:{access}}}= useContext(Context)
    const handleAgent=()=>{
        accountsConfig.post('api/v1/accounts/create-agent/',{
            name:agentName,
            address:agentAddress,
            phone:agentNumber,
        },{
            headers: {
                Authorization: `Bearer ${access}`,
            },
        }).then(function(response){
            if(response.data.StatusCode===6000){
                setActiveAgent(true)
                setResponseData(response.data.data.agent_code)
            }else{
                window.location.reload()
            }
        })
    }

    return (
        <>
            <Cover>
                <Top>
                    <h4>Dett Pre-booking (Agents)</h4>
                    <h5>Visit site</h5>
                </Top>
                <FormSection>
                    <SectionCover>
                        <Name>
                            <Label>Enter agent name</Label>
                            <InputCover>
                                <InputField type="text" onChange={(e)=>setAgentName(e.target.value)}/>
                            </InputCover>
                        </Name>
                        <Name>
                            <Label>Enter agent address</Label>
                            <InputCover>
                                <InputFieldAddress type="" onChange={(e)=>setAgentAddress(e.target.value)}/>
                            </InputCover>
                        </Name>
                        <Name>
                            <Label>Enter contact number</Label>
                            <InputCover>
                                <InputField type="text" onChange={(e)=>setAgentNumber(e.target.value)}/>
                            </InputCover>
                        </Name>
                    </SectionCover>
                    {/* <ButtonSubmit onClick={()=>setActiveAgent((prev)=>!prev)}>Generate Code</ButtonSubmit> */}
                    <ButtonSubmit onClick={()=>handleAgent()}>Generate Code</ButtonSubmit>
                </FormSection>
                <AgentModal activeAgent={activeAgent} setActiveAgent={setActiveAgent} responseData={responseData} agentName={agentName}/>
            </Cover>
        </>
    );
};

export default AgentForm;
const Cover = styled.div`
    /* margin-top: 120px;
    margin-left: 22%; */
    margin-left: 28px;
    margin-top: 117px;
    /* width: 72.8%; */
    background: #ebebeb;
    /* margin-top:30px; */
    width: 94%;
    /* height: 390px; */
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 30px;
    color: #000;
    position: relative;
`;
const Top = styled.div`
    h4 {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        color: #2c3333;
    }
    h5 {
        margin: 0;
        color: #bcbcbc;
        cursor: pointer;
        margin-top: 10px;
    }
`;
const FormSection = styled.div`
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    position: relative;
`;
const Name = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;
const Label = styled.div`
    width: 30%;
`;
const SectionCover = styled.div`
    width: 60%;
`;
const InputCover = styled.div`
    border: 1px solid #707070;
    width: 60%;
    overflow: hidden;
    border-radius: 4px;
`;
const InputField = styled.input`
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
`;
const InputFieldAddress = styled.textarea`
    border: none;
    outline: none;
    width: 100%;
    height: 120px;
`;
const ButtonSubmit = styled.div`
    width: 150px;
    height: 50px;
    background: green;
    float: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
`;
