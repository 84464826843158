import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Context } from "../../Context/Store";
import SideBar from "../../includes/SideBar";
import Topbar from "../../includes/Topbar";
import AgentForm from "../../screens/AgentForm";
import AllOccasion from "../../screens/AllOccasion";
import Home from "../../screens/Home";
import HomeCover from "../../screens/HomeCover";
import OccasionCalendar from "../../screens/OccasionCalendar";
import AgentPrintScreen from "../../screens/printingscreens/AgentPrintScreen";
import AllOccasionsData from "../../screens/printingscreens/AllOccasionsData";
import PrintScreenCover from "../../screens/printingscreens/PrintScreenCover";
import UserPrintScreen from "../../screens/printingscreens/UserPrintScreen";
import UpcomingOccasion from "../../screens/UpcomingOccasion";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "./AppRouter";
import AuthRouter from "./AuthRouter";

const MainRouter = () => {
    const { state, dispatch } = useContext(Context);
    useEffect(() => {
        let userData = localStorage.getItem("userData");
        userData = JSON.parse(userData);
        dispatch({
            type: "UPDATE_USER_DATA",
            payload: userData,
        });
    }, []);
    return (
        <div>
            {/* <Topbar/> */}
            {/* <SideBar/> */}
            {/* <Home/> */}
            {/* <HomeCover/> */}
            {/* <UpcomingOccasion/> */}
            {/* <AllOccasion/>  */}
            {/* <AgentForm/> */}
            {/* <AllOccasionsData />   */}
            {/* <BrowserRouter>
          <Routes>
            <Route path='/' element={<Topbar/><Sidebar/><AllOccasion/>}
            <Route path='/print' element={<PrintScreenCover/>}/>
          </Routes>
        
        </BrowserRouter> */}
            {/* <OccasionCalendar/> */}
            <Routes>
                <Route
                    path="/auth"
                    element={
                        <AuthRoute>
                            <AuthRouter />
                        </AuthRoute>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <AppRouter />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/print"
                    element={<PrintScreenCover />}
                />
                <Route
                    path="/printagent"
                    element={<AgentPrintScreen />}
                />
                <Route
                    path="/printuser"
                    element={<UserPrintScreen />}
                />
            </Routes>
        </div>
    );
};

export default MainRouter;
