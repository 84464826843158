import React, { useContext, useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../Context/Store";
import { accountsConfig } from "../../helpers/axiosConfig";
import AddRound from "../../assets/images/addround.png";

const AllOccasionsData = ({ type }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const datatype = searchParams.get("datatype");
    const [dropDown, setDropDown] = useState(false);
    const [alldate, setAllDate] = useState([]);

    const { state } = useContext(Context);
    const { access } = state.userData;
    const {
        state: { selectedMonth },
    } = useContext(Context);
    console.log(datatype);

    const [page, setPage] = useState(1);
    const [totalpage, setTotalpage] = useState(null);
    const [dataCount, setDataCount] = useState(null);
    const [dataperPage, setDataperPage] = useState(15);
    const columns = [
        {
            name: "Si.No",
            selector: (row) => row.id,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Contact",
            selector: (row) => row.contact,
        },
        {
            name: "For whom",
            selector: (row) => row.forwhom,
        },
        {
            name: "Relation",
            selector: (row) => row.relation,
        },
        {
            name: "Occasion",
            selector: (row) => row.occasion,
        },
        {
            name: "Occasion Date",
            selector: (row) => row.occasiondate,
        },
        {
            name: "Gift",
            selector: (row) => row.gift,
        },
        {
            name: "Status",
            selector: (row) => row.status,
        },
    ];
    // console.log('hello world');
    const dropData = [
        {
            id: 1,
            name: "Confirm",
            data: "confirm",
        },
        {
            id: 2,
            name: "Rejected",
            data: "rejected",
        },
        {
            id: 3,
            name: "Pending",
            data: "pending",
        },
        {
            id: 4,
            name: "Not answered",
            data: "not_answered",
        },
    ];
    const [sortData, setSortData] = useState([]);
    useEffect(() => {
        const sorted = [...alldate].sort((a, b) => {
            return b.occasion_date - a.occasion_date;
        });
        alldate.map((item) => {});
        // setDogList(sorted);
        //alert(sorted[0].name);
    }, []);
    //     const handleModal =() =>{
    //         setDropDown('')
    //     }
    //     function useOutsideAlerter(ref){
    //     useEffect(() => {
    //         function handleClickOutside(event) {
    //             if (ref.current && !ref.current.contains(event.target)) {
    //                 handleModal();
    //             }
    //         }
    //         document.addEventListener("mousedown", handleClickOutside);
    //         return () => {
    //             document.removeEventListener("mousedown", handleClickOutside);
    //         };
    //     }, [ref]);
    // }
    //dddd
    const dropBox = useRef(null);
    const toggleSetVisible = () => {
        // setModal(false);
        setDropDown("");
    };
    // outside
    const handleClickOutside = (event) => {
        // console.log(event);
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setDropDown("");
            }
        }
    };
    useEffect(() => {
        document.addEventListener("mouseup", handleClickOutside);
        document.addEventListener("touchend", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
            document.removeEventListener("touchend", handleClickOutside);
        };
    }, []);

    //end outside event handler
    useEffect((event) => {
        handleClickOutside(event);
    }, []);
    const wrapperRef = useRef(null);
    // console.log(wrapperRef, "refffere");

    console.log(type, "type");

    useEffect(() => {
        if (type === "alloccasion" || (datatype === "alloccasion" && selectedMonth === "all")) {
            // console.log("allocasion check");
            // console.log('all in one')
            if (selectedMonth === "all") {
                // console.log(selectedMonth, "-=-=-==-==hai hello=-==-=");
                accountsConfig
                    .get("api/v1/bookings/bookings/list/", {
                        headers: {
                            Authorization: `Bearer ${access}`,
                        },
                        params: {
                            page: page,
                            instances_count: dataperPage,
                        },
                    })
                    .then(function (res) {
                        setAllDate(res.data.data);
                        setTotalpage(res.data.pagination_data.total_pages);
                        // console.log(alldate);
                    });
            } else if (selectedMonth != "all") {
                // console.log('on month');
                accountsConfig
                    .get(`api/v1/bookings/bookings/sorted-list/?month=2022-${selectedMonth}-01`, {
                        headers: {
                            Authorization: `Bearer ${access}`,
                        },
                    })
                    .then(function (res) {
                        setAllDate(res.data.data.data.data);
                        // console.log(alldate,'asdfghj');
                    });
            }
        } else if (type === "upcoming" || datatype === "upcoming") {
            // console.log("upcoming");
            accountsConfig
                .get("api/v1/bookings/upcoming/bookings/list", {
                    headers: {
                        Authorization: `Bearer ${access}`,
                    },
                })
                .then(function (res) {
                    setAllDate(res.data.data.data);
                });
        } else if (selectedMonth != "all") {
            accountsConfig
                .get(`api/v1/bookings/bookings/list/?month=2022-${selectedMonth}-01`, {
                    headers: {
                        Authorization: `Bearer ${access}`,
                    },
                })
                .then(function (res) {
                    setAllDate(res.data.data.data.data);
                });
        }
    }, [selectedMonth, dropDown, page]);
    const handleStatus = (drops, item) => {
        // console.log("hai");
        accountsConfig
            .post(
                `api/v1/bookings/status-update/${item.id}/`,
                {
                    status: drops.data,
                },
                {
                    headers: {
                        Authorization: `Bearer ${access}`,
                    },
                }
            )
            .then(function (response) {
                setDropDown("");
                // console.log(response);
            });
    };

    return (
        <Cover type={type}>
            <Table>
                <tbody>
                    <TableCover>
                        <TableCover>
                            {columns.map((item) => (
                                <TableHead type={item}>{item.name}</TableHead>
                            ))}
                        </TableCover>
                        {alldate.map((item, index) => {
                            const serialNumber = (page - 1) * dataperPage + index + 1;
                            return (
                                <TableCover>
                                    <TableData type="sno">{serialNumber}</TableData>
                                    <TableData>{item.name}</TableData>
                                    <TableData>{item.phone}</TableData>
                                    <TableData>{item.relation_name}</TableData>
                                    <TableData>{item.relationship}</TableData>
                                    <TableData>{item.occasion}</TableData>
                                    <TableData>{item.occasion_date}</TableData>
                                    <TableData>{item.gift_suggestion}</TableData>
                                    <TableDataStatus
                                        onClick={() => setDropDown(item.id)}
                                        status={item.status}
                                    >
                                        {item.status}{" "}
                                        <img
                                            src={AddRound}
                                            alt={item.name}
                                        />
                                        <Dropdown className={item.id === dropDown && "active"}>
                                            {/* <Change ref={wrapperRef}> */}
                                            {dropData.map((drops) => (
                                                <DataContain
                                                    className="claaass"
                                                    onClick={() => handleStatus(drops, item)}
                                                    value={drops.data}
                                                >
                                                    {drops.name}
                                                </DataContain>
                                            ))}
                                            {/* </Change> */}
                                        </Dropdown>
                                    </TableDataStatus>
                                </TableCover>
                            );
                        })}
                        <PaginationBox>
                            {totalpage > 1 && (
                                <button
                                    onClick={() =>
                                        setPage((prev) => (prev - 1 > 0 ? prev - 1 : prev))
                                    }
                                >
                                    {"<"}
                                </button>
                            )}

                            {totalpage > 0 && (
                                <>
                                    {page > 3 && (
                                        <>
                                            <button onClick={() => setPage(1)}>{1}</button>
                                            {page > 4 && <span>...</span>}
                                        </>
                                    )}

                                    {Array(totalpage)
                                        .fill()
                                        .map((_, index) => {
                                            const pageNumber = index + 1;
                                            if (
                                                (page <= 3 && pageNumber <= 5) ||
                                                (page > 3 &&
                                                    page < totalpage - 2 &&
                                                    pageNumber >= page - 2 &&
                                                    pageNumber <= page + 2) ||
                                                (page >= totalpage - 2 &&
                                                    pageNumber >= totalpage - 4)
                                            ) {
                                                return (
                                                    <button
                                                        onClick={() => setPage(pageNumber)}
                                                        key={index}
                                                        className={
                                                            page === pageNumber ? `active-btn` : ""
                                                        }
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                );
                                            }
                                            return null;
                                        })}

                                    {page < totalpage - 2 && (
                                        <>
                                            {page < totalpage - 4 && <span>...</span>}
                                            <button onClick={() => setPage(totalpage)}>
                                                {totalpage}
                                            </button>
                                        </>
                                    )}
                                </>
                            )}

                            {totalpage > 1 && (
                                <button
                                    onClick={() =>
                                        setPage((prev) =>
                                            prev + 1 <= totalpage ? prev + 1 : totalpage
                                        )
                                    }
                                >
                                    {">"}
                                </button>
                            )}
                        </PaginationBox>
                    </TableCover>
                </tbody>
            </Table>
        </Cover>
    );
};

export default AllOccasionsData;
const Change = styled.div``;
const Cover = styled.div`
    background: #fff;
    /* background-color: ${({ type }) => (type === "all-occassion" ? "red" : "#fff")}; */
    /* padding: 20px; */
    width: 98%;
    margin: 0 auto;
`;
const Table = styled.table`
    /* border: 1px solid #000; */
    width: 100%;
    tbody {
        width: 100%;
    }
`;
const Dropdown = styled.div`
    position: absolute;
    width: 120px;
    background-color: #fff;
    display: none;
    z-index: 20;
    left: -10%;
    border: 1px solid #707070;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: inset 1px 1px 5px #fff;

    &.active {
        display: block;
    }
`;
const DataContain = styled.div`
    background-color: ${({ value }) =>
        value === "confirm"
            ? "green"
            : value === "not_answered"
            ? "rebeccapurple"
            : value === "rejected"
            ? "red"
            : value === "pending" && "blue"};
    border-bottom: 1px solid #707070;
    text-align: center;
    z-index: 10;
    /* color: #707070; */
    color: #fff;
    &:last-child {
        border-bottom: none;
    }
`;
const TableCover = styled.tr`
    /* border: 1px solid #000; */
`;
const TableHead = styled.th`
    /* width: 12%; */
    width: ${({ type }) =>
        type.name === "Si.No" ? "5%" : type.status === "Status" ? "15%" : "11%"};
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
`;
const TableData = styled.td`
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    text-align: center;
    height: 50px;
    width: calc(100% / 8);
    overflow: hidden;
`;
const TableDataStatus = styled.td`
    /* border: 1px solid #000; */
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%);
    height: 50px;
    color: ${({ status }) =>
        status === "confirm"
            ? "#31A818"
            : status === "rejected"
            ? "#FF0000"
            : status === "Pending"
            ? "#FF9101"
            : "#161261"};
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
`;

//pagination
const PaginationBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;

    button {
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: #f1f1f1;
        background-color: #1c1c1c;
        font-weight: bold;
        user-select: none;

        &.active-btn {
            background-color: #12947f;
        }

        :hover {
            opacity: 0.8;
        }
    }

    span {
        cursor: pointer;
        user-select: none;
    }
`;
