import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Home from "./Home";
import PrintIcon from "../assets/images/printicon.png";
import DataTable from "react-data-table-component";
import AllOccasionsData from "./printingscreens/AllOccasionsData";
import { useNavigate, Link } from "react-router-dom";
import DropDownIco from "../assets/images/dropdown.png";

import { ascend, descend, prop, sort } from "ramda";
import { Context } from "../Context/Store";

const AllOccasion = () => {
    const [selectedMonth, setSelectedMonth] = useState("all");
    const [filterModal, setFilterModal] = useState(false);
    const [isMonth, setIsMonth] = useState("january");
    const { state, dispatch } = useContext(Context);
    // const navigate=useNavigate()
    // const handlePrint=()=>{
    //     navigate('/print')
    // }
    const Month = [
        {
            id: "all",
            month: "all",
        },
        {
            id: "01",
            month: "january",
        },
        {
            id: "02",
            month: "february",
        },
        {
            id: "03",
            month: "march",
        },
        {
            id: "04",
            month: "april",
        },
        {
            id: "05",
            month: "may",
        },
        {
            id: "06",
            month: "june",
        },
        {
            id: "07",
            month: "july",
        },
        {
            id: "08",
            month: "augest",
        },
        {
            id: "09",
            month: "september",
        },
        {
            id: "10",
            month: "october",
        },
        {
            id: "11",
            month: "november",
        },
        {
            id: "12",
            month: "december",
        },
    ];
    const type = "alloccasion";
    // const type ='alloccassion'
    const handleMonth = (item) => {
        setSelectedMonth(item.id);
        setIsMonth(item.month);
        setFilterModal((prev) => !prev);
        dispatch({
            type: "UPDATE_SELECTED_MONTH",
            selectedMonth: item.id,
        });
    };
    // console.log(state);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());
    const [scrollY, setScrollY] = useState(bodyOffset.top);
    const [scrollX, setScrollX] = useState(bodyOffset.left);
    const [scrollDirection, setScrollDirection] = useState();

    const listener = (e) => {
        setBodyOffset(document.body.getBoundingClientRect());
        setScrollY(-bodyOffset.top);
        setScrollX(bodyOffset.left);
        setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
        setLastScrollTop(-bodyOffset.top);
    };

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    }, [scrollY]);
    useEffect(() => {
        if (scrollY > 26) {
            // console.log("hello scroll end")
        }
    }, scrollY);

    //   console.log(scrollY,'-=-=-==-=-=-=-=');

    return (
        <Cover>
            <Home type={type} />
            <UpcomingContainer>
                <TopContainer>
                    <SortButton>
                        <FirstBox>Sort By</FirstBox>
                        <FirstBox onClick={() => setFilterModal((prev) => !prev)}>
                            {isMonth}
                            <img
                                src={DropDownIco}
                                alt="image"
                            />
                        </FirstBox>
                        <MonthDrop filterModal={filterModal}>
                            {Month.map((item) => (
                                <DataMonth
                                    onClick={() => {
                                        handleMonth(item);
                                    }}
                                >
                                    {item.month}
                                </DataMonth>
                            ))}
                        </MonthDrop>
                    </SortButton>
                    <PrintButton to="/print?datatype=alloccasion">
                        {/* <PrintButton> */}
                        <img
                            src={PrintIcon}
                            alt="image"
                        />{" "}
                        Print
                    </PrintButton>
                </TopContainer>

                <TableContainer>
                    {/* <DataTable columns={columns} data={data} /> */}
                    <AllOccasionsData
                        type={type}
                        selectedMonth={selectedMonth}
                    />
                </TableContainer>
            </UpcomingContainer>
        </Cover>
    );
};

export default AllOccasion;
const Cover = styled.div`
    /* margin-top: 100px; */
    /* margin-left: 20%; */
    /* width: 72.8%; */
    /* height: 360px; */
    padding: 30px;
    color: #000;
    position: relative;
`;
const UpcomingContainer = styled.div`
    width: 95%;
    height: 250px;
    /* background: red;      */
    left: 5%;
    position: absolute;
    top: 55%;
`;
const DataMonth = styled.div`
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    height: 25px;
    color: #4a3d3d;
    font-weight: bold;
    cursor: pointer;
`;
const PrintButton = styled(Link)`
    width: 100px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* float: right; */
    cursor: pointer;
    border: 1px solid #e1dede;
    border-radius: 5px;
    img {
        margin-right: 5px;
    }
`;
const MonthDrop = styled.div`
    position: absolute;
    width: 120px;
    height: 200px;
    transform: ${({ filterModal }) => (filterModal ? "scale(1,1)" : "scale(0,0)")};
    transition: 0.4s ease;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 8px;
    /* s */
    /* background: red; */
    right: 0;
`;
const TableContainer = styled.div`
    width: 100%;
    height: calc(100vh - 65vh);
    /* height: 90%; */
    /* color:#000; */
    /* background: red; */
    overflow-y: scroll;
    margin-top: 20px;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const FirstBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e1dede;
    height: 100%;
    width: 50%;
    cursor: pointer;
    img {
        margin-left: 8px;
    }
`;
const SortButton = styled.div`
    width: 210px;
    height: 35px;
    background: #fff;
    color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* float: right; */
    border: 1px solid #e1dede;
    border-radius: 5px;
`;
