import React from "react";
import styled from "styled-components";
import Home from "./Home";
import PrintIcon from "../assets/images/printicon.png";
import DataTable from "react-data-table-component";
import AllOccasionsData from "./printingscreens/AllOccasionsData";
import { useNavigate,Link } from "react-router-dom";
import AllAgentData from "./printingscreens/AlllAgentData";

const ViewAgent = () => {
    // const navigate=useNavigate()
    // const handlePrint=()=>{
    //     navigate('/print')
    // }
    return (
        <Cover>
            <Home />
            <UpcomingContainer>
                <TopContainer>
                    <PrintButton to='/printagent'>
                    {/* <PrintButton> */}
                        <img src={PrintIcon} alt="image" /> Print
                    </PrintButton>
                    <SortButton>
                        <FirstBox>
                            Sort By
                        </FirstBox>
                        <FirstBox>
                            January
                        </FirstBox>
                    </SortButton>
                </TopContainer>

                <TableContainer>
                    {/* <DataTable columns={columns} data={data} /> */}
                    <AllAgentData/>
                </TableContainer>
            </UpcomingContainer>
        </Cover>
    );
};

export default ViewAgent;
const Cover = styled.div`
    /* margin-top: 100px; */
    /* margin-left: 20%; */
    /* width: 72.8%; */
    /* height: 360px; */
    padding: 30px;
    color: #000;
    position: relative;
`;
const UpcomingContainer = styled.div`
    width: 95%;
    height: 250px;
    /* background: red;      */
    left: 5%;
    position: absolute;
    top: 55%;
`;
const PrintButton = styled(Link)`
    width: 100px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* float: right; */
    cursor:pointer;
    border: 1px solid #e1dede;
    border-radius: 5px;
    img {
        margin-right: 5px;
    }
`;
const TableContainer = styled.div`
    width: 80%;
    height:220px;
    /* height: 90%; */
    /* color:#000; */
    /* background: red; */
    overflow-y: scroll;
    margin-top: 20px;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const FirstBox =styled.div`
    display :flex;
    justify-content: center;
    align-items: center;
    border-left:1px solid #e1dede;
    height: 100%;
    width: 50%;
    
`
const SortButton = styled.div`
    width: 150px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* float: right; */
    border: 1px solid #e1dede;
    border-radius: 5px;
    display:none;
`