import React, { useContext, useState } from "react";
import styled from "styled-components";
import Home from "./Home";
import PrintIcon from "../assets/images/printicon.png";
import DropDownIco from "../assets/images/dropdown.png";
import DataTable from "react-data-table-component";
import AllOccasionsData from "./printingscreens/AllOccasionsData";
import { useNavigate, Link } from "react-router-dom";
import OccasionCalendarData from "./printingscreens/OccasionCalendarData";
import { Context } from "../Context/Store";
import UserData from "./printingscreens/UserData";

const RegisteredUser = () => {
    const [filterModal, setFilterModal] = useState("");
    const [statusFilter, setStatusFilter] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState("all");
    const [selectedFilter, setSelectedFilter] = useState("all");
    const [showDate, setShowDate] = useState("all");
    const [showStatus, setShowStatus] = useState("all");
    const { dispatch } = useContext(Context);
    const Month = [
        {
            id: "all",
            month: "all",
        },
        {
            id: "01",
            month: "january",
        },
        {
            id: "02",
            month: "february",
        },
        {
            id: "03",
            month: "march",
        },
        {
            id: "04",
            month: "april",
        },
        {
            id: "05",
            month: "may",
        },
        {
            id: "06",
            month: "june",
        },
        {
            id: "07",
            month: "july",
        },
        {
            id: "08",
            month: "augest",
        },
        {
            id: "09",
            month: "september",
        },
        {
            id: "10",
            month: "october",
        },
        {
            id: "11",
            month: "november",
        },
        {
            id: "12",
            month: "december",
        },
    ];
    const dropData = [
        {
            id: 1,
            name: "Confirm",
            data: "confirm",
        },
        {
            id: 2,
            name: "Rejected",
            data: "rejected",
        },
        {
            id: 3,
            name: "Pending",
            data: "pending",
        },
        {
            id: 4,
            name: "Not answered",
            data: "not_answered",
        },
        {
            id: 5,
            name: "all",
            data: "all",
        },
    ];
    // const navigate=useNavigate()
    // const handlePrint=()=>{
    //     navigate('/print')
    // }
    const handleMonth = (item) => {
        setFilterModal((prev) => !prev);
        setSelectedMonth(item.id);
        setShowDate(item.month);
        dispatch({
            type: "UPDATE_SELECTED_MONTH",
            selectedMonth: item.id,
        });
    };
    const handleStatusFilter = (item) => {
        setStatusFilter((prev) => !prev);
        setSelectedFilter(item.data);
        setShowStatus(item.name);
    };
    return (
        <Cover>
            <Home />
            <UpcomingContainer>
                <TopContainer>
                    {/* <SortWrap>
                        <SortButton>
                            <FirstBox>Sort By</FirstBox>
                            <FirstBox
                                onClick={() => setFilterModal((prev) => !prev)}
                            >
                                {showDate}
                                <img src={DropDownIco} alt="image" />
                            </FirstBox>
                            <MonthDrop filterModal={filterModal}>
                                {Month.map((item) => (
                                    <DataMonth
                                        onClick={() => {
                                            handleMonth(item);
                                        }}
                                    >
                                        {item.month}
                                    </DataMonth>
                                ))}
                            </MonthDrop>
                        </SortButton>
                        <SortButton>
                            <FirstBox>Sort By</FirstBox>
                            <FirstBox
                                onClick={() => setStatusFilter((prev) => !prev)}
                            >
                                {showStatus}
                                <img src={DropDownIco} alt="image" />
                            </FirstBox>
                            <StatusDrop statusFilter={statusFilter}>
                                {dropData.map((item) => (
                                    <DataStatus
                                        onClick={() => handleStatusFilter(item)}
                                    >
                                        {item.name}
                                    </DataStatus>
                                ))}
                            </StatusDrop>
                        </SortButton>
                    </SortWrap> */}
                    <PrintButton to="/print">
                        {/* <PrintButton> */}
                        <img src={PrintIcon} alt="image" /> Print
                    </PrintButton>
                </TopContainer>

                <TableContainer>
                    {/* <DataTable columns={columns} data={data} /> */}
                    <UserData/>
                </TableContainer>
            </UpcomingContainer>
        </Cover>
    );
};

export default RegisteredUser;
const Cover = styled.div`
    margin-top: 100px;
    /* margin-left: 20%; */
    /* width: 72.8%; */
    height: 360px;
    padding: 30px;
    color: #000;
    position: relative;
`;
const StatusDrop = styled.div`
    position: absolute;
    width: 120px;
    /* height: 200px; */
    transform: ${({ statusFilter }) =>
        statusFilter ? "scale(1,1)" : "scale(0,0)"};
    transition: 0.4s ease;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 8px;
    /* s */
    /* background: red; */
    right: 0;
`;
const UpcomingContainer = styled.div`
    width: 95%;
    height: 250px;
    /* background: red;      */
    left: 5%;
    position: absolute;
    top: 65%;
`;
const DataStatus = styled.div`
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    height: 25px;
    color: #4a3d3d;
    font-weight: bold;
    cursor: pointer;
`;
const PrintButton = styled(Link)`
    width: 100px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* float: right; */
    cursor: pointer;
    border: 1px solid #e1dede;
    border-radius: 5px;
    img {
        margin-right: 5px;
    }
`;
const SortWrap = styled.div`
    display: flex;
`;
const TableContainer = styled.div`
    /* width: 70%; */
    /* height: 200px; */
    height: calc(100vh - 65vh);
    /* color:#000; */
    /* background: red; */
    overflow-y: scroll;
    background-color: #fff;
    margin-top: 28px;
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const FirstBox = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e1dede;
    height: 100%;
    width: 50%;
    img {
        margin-left: 8px;
    }
`;
const MonthDrop = styled.div`
    position: absolute;
    width: 120px;
    height: 200px;
    transform: ${({ filterModal }) =>
        filterModal ? "scale(1,1)" : "scale(0,0)"};
    transition: 0.4s ease;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 8px;
    /* s */
    /* background: red; */
    right: 0;
`;
const DataMonth = styled.div`
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    height: 25px;
    color: #4a3d3d;
    font-weight: bold;
    cursor: pointer;
`;
const SortButton = styled.div`
    width: 170px;
    height: 35px;
    background: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: relative;
    /* float: right; */
    border: 1px solid #e1dede;
    border-radius: 5px;
`;
