import React from "react";
import { Route, Routes } from "react-router-dom";
import SideBar from "../../includes/SideBar";
import Topbar from "../../includes/Topbar";
import AgentForm from "../../screens/AgentForm";
import AllOccasion from "../../screens/AllOccasion";
import HomeCover from "../../screens/HomeCover";
import OccasionCalendar from "../../screens/OccasionCalendar";
import PrintScreenCover from "../../screens/printingscreens/PrintScreenCover";
import styled from "styled-components";
import ViewAgent from "../../screens/ViewAgent";
import AddUser from "../../screens/AddUser";
import UpcomingOccasion from "../../screens/UpcomingOccasion";
import UserView from "../../screens/UserView";
import RegisteredUser from "../../screens/RegisteredUser";
import WomensDay from "../../screens/WomensDay";

const AppRouter = () => {
    return (
        <>
            <SideBar />
            <Topbar />
            <Cover>
                <Routes>
                    <Route
                        path="/"
                        element={<HomeCover />}
                    />
                    <Route
                        path="alloccasion"
                        element={<AllOccasion />}
                    />
                    <Route
                        path="calendar"
                        element={<OccasionCalendar />}
                    />
                    <Route
                        path="agent"
                        element={<AgentForm />}
                    />
                    <Route
                        path="agentdata"
                        element={<ViewAgent />}
                    />
                    <Route
                        path="adduser"
                        element={<AddUser />}
                    />
                    <Route
                        path="upcomingoccasion"
                        element={<UpcomingOccasion />}
                    />
                    <Route
                        path="viewusers"
                        element={<UserView />}
                    />
                    <Route
                        path="registerd-user"
                        element={<RegisteredUser />}
                    />
                    <Route
                        path="womensday"
                        element={<WomensDay />}
                    />
                    {/* <Route path='print' element={<PrintScreenCover/>}/> */}
                </Routes>
            </Cover>
        </>
    );
};

export default AppRouter;
const Cover = styled.div`
    margin-top: 7%;
    width: 77%;
    margin-left: 20%;
    @media (max-width: 880px) {
        margin-left: 0;
    }
    @media (max-width: 640px) {
        width: 90%;
    }
`;
